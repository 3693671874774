import React from 'react'
import PropTypes from "prop-types"
import styles from "./index.module.scss"

const Heading1 = ({ children }) => (
  <h1 className={styles.heading}>
    { children }
  </h1>
)

Heading1.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Heading1
