import React from 'react'
import styles from "./index.module.scss"

const Footer = ({ siteTitle }) => (
  <footer>
    <p className={ styles.footer__text }>
      2020 Ohnami development
    </p>
  </footer>

)
export default Footer
