import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./index.module.scss"

class Header extends React.Component {
  // 状態を管理するフラグ
  state = { showMenu: false }

  // メニューの状態をフラグで管理
  toggleMenu = e => {
    e.preventDefault()
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  toggleTop = e => {
    this.setState({
      showMenu: false,
    })
  }

  render() {
    // メニュー展開ボタンの表示切り替え
    const menuButtonActive = this.state.showMenu
      ? `${styles.header__menu_button_active}`
      : `${styles.header__menu_button_deactive}`
    const menuButtonDeactive = this.state.showMenu
      ? `${styles.header__menu_button_deactive}`
      : `${styles.header__menu_button_active}`

    // メニュー一覧の表示非表示の切り替え
    const menuListActive = this.state.showMenu
      ? `${styles.navigation__active}`
      : ""

    return (
      <div>
        <div>
          <header className={styles.header}>
            <div className={styles.container}>
              <div className={styles.header__left}>
                <Link to="/" onClick={this.toggleTop}>
                  <img
                    src="/images/logo.svg"
                    alt="ロゴのアイコンです。我が家の家紋、マルに二つ引きといいます。"
                    className={styles.header__title_logo}
                  ></img>
                  <p className={styles.header__title}>
                    <span className={styles.header__title_text}>
                      {this.props.siteTitle}
                    </span>
                  </p>
                </Link>
              </div>

              <div className={styles.header__right}>
                <a
                  href="https://www.youtube.com/channel/UCMPIUab4UhzmXd8pn3SmlBw/featured"
                  className={`${styles.header__twitter_link}`}
                >
                  <img
                    src="/icons/youtube-brands.svg"
                    alt="Youtubeのチャンネルはこちらです"
                  ></img>
                </a>
                <a
                  href="https://twitter.com/z_ohnami"
                  className={`${styles.header__twitter_link}`}
                >
                  <img
                    src="/icons/twitter_icon.svg"
                    alt="Twitterアカウントはこちらです"
                  ></img>
                </a>
                <Link
                  to="/"
                  className={`${styles.header__menu_button}`}
                  onClick={this.toggleMenu}
                >
                  <img
                    src="/icons/menu.svg"
                    alt="メニュー開く"
                    className={`${menuButtonDeactive}`}
                  ></img>
                  <img
                    src="/icons/close_button.svg"
                    alt="メニュー閉じる"
                    className={`${menuButtonActive}`}
                  ></img>
                </Link>
              </div>
            </div>
          </header>
        </div>

        <div className={`${styles.navigation} ${menuListActive}`}>
          <ul className={styles.navigation__list}>
            <li className={styles.navigation__list_item}>
              <Link to="/" onClick={this.toggleTop}>
                <div className={styles.triangle}></div>
                トップページ/記事一覧
              </Link>
            </li>
            <li className={styles.navigation__list_item}>
              <Link to="/works">
                <div className={styles.triangle}></div>
                作品/ポートフォリオ
              </Link>
            </li>
            <li className={styles.navigation__list_item}>
              <Link to="/about">
                <div className={styles.triangle}></div>
                プロフィール
              </Link>
            </li>
            <li className={styles.navigation__list_item}>
              <Link to="/contact">
                <div className={styles.triangle}></div>
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
